.App {
  text-align: center;
  background-color: #CC0000;
}

body {
  background-color: #333;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 850px;
  height: 100vh;
}

.App-header {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 20px;
}

.online-view, .offline-view {
  margin: 20px;
  animation: fadeIn 1s ease-in-out;
}

.online-view {
  margin-top: -80px;
}

.main-heading {
  font-size: 130px; /* Ensuring the headline is large */
  color: #000;
  text-align: left;
}


.highlight {
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}

.headertitle0 {
  background-color: #fff;
  color: #CC0000;
}

.headertitle {
  color: #fff;
  display: block;
}

.headerinner {
  font-size: 30px;
}

.sub-heading {
  font-size: 46px;
  color: #fff;
  margin: 0 auto;
  margin-top: 120px !important;
  margin-bottom: 50px;
  text-shadow: 1px 1px 3px #000;
  text-align: center;
}

.sub-headingonline {
  text-align: left;
}

.sub-head2 {
  font-size: 32px;
}

.primary-text {
  font-size: 1.5em;
  color: #fff;
  margin: 20px auto;
  max-width: 800px;
  text-align: center;
}

.supportive-text {
  font-size: 1.2em;
  color: #fff;
  margin: 0 auto;
  margin: 20px auto;
  max-width: 800px;
  text-align: left;
  list-style-type: disc;
  padding-left: 20px;
  width: 100%;
  max-width: 500px;
  margin-top: 55px;
  margin-bottom: 80px;
}

.supportive-text li {
  margin-bottom: 10px;
}

.content-container {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  width: 100%;
  max-width: 1000px;
}

.left-box, .right-box {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  flex: 1;
  margin: 10px;
  position: relative;
}

.cross-icon, .tick-icon {
  font-size: 9em; /* Increased size */
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.cross-icon {
  color: red;
}

.tick-icon {
  color: green;
}

.left-box h3, .right-box h3 {
  font-size: 2em;
  color: #ffcc00;
}

.left-box ul, .right-box ul {
  list-style-type: none;
  padding: 0;
  font-size: 1.5em;
  color: #fff;
}

.offline-view h1 {
  color: #fff;
  font-size: 24px;
  background: #c00;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  line-height: 1.5;
}

.offline-view h2 {
  font-size: 2.5em;
  color: #fff;
  margin-top: 80px;
  max-width: 600px;
}

.poem {
  font-size: 1.5em;
  color: transparent;
  max-width: 800px;
  margin: 20px auto;
  text-align: center;
}

.offline-message {
  background-color: #444;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin: 20px auto;
  max-width: 800px;
  font-size: 1.5em;
  color: #fff;
  text-align: left;
  line-height: 1.6;
}

.cta-container {
  position: absolute;
  width: 700px;
}

.cta-container2 {
  margin-top: 60px !important;
  position: relative;
  margin-bottom: 30px;
}

.email-input {
  background: #465a7d66;
  border: 1px solid #ccc;
  border-radius: 5px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  color: #fff;
  flex-grow: 1;
  font-size: 1.2em;
  height: 24px;
  margin-right: 10px;
  padding: 10px;
  vertical-align: middle;
}

.email-input::placeholder {
  color: #cecece;
}

.cta-button {
  font-size: 1em;
  color: #fff;
  background-color: #aa0000;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: -10px;
  vertical-align: middle;
  max-height: 48px;
}

.cta-button:hover {
  background-color: #333;
}

.cta-button.secondary {
  background-color: #ffcc00;
  color: #333;
}

.cta-button.secondary:hover {
  background-color: #cc9900;
}

.cta-button2 {
  transition: none !important;
  animation: none !important;
}

.cta2 {
  margin-top: -50px;
  margin-bottom: 80px;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}
.cta-button {
  animation: pulse 2s infinite;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.75); /* Black w/ opacity */
}

.modal.show {
  display: block; /* Show the modal */
  z-index: 5;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal .cta-button {
  font-size: 1.5em;
  color: #fff;
  background-color: #000;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal .cta-button:hover {
  background-color: #333;
}

.modal .cta-button.disabled {
  background-color: #888;
  cursor: not-allowed;
  animation: none;
  opacity: 0.2;
}

.loader {
  width: 280px;
  height: auto;
  margin: 20px auto;
  display: block;
}

.Appoffline {
  background-color: #333;
}

.highlightoffline {
  border-radius: 5px;
  color: #fff;
  font-size: 30px !important;
  padding-left: 10px;
  text-align: center;
  display: block;
}

.fonts {
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: absolute;
}
.fonts1 {
  background-position: 0 -33px;
  height: 20px;
  margin-left: 5px;
  margin-top: 4px;
  width: 25px;
  position: relative;
  background-size: 20px;
}
.fonts2 {
  background-position: 0 23px;
  height: 37px;
  width: 28px;
  margin-top: -14px;
  margin-left: 5px;
}

/* Add these new styles */
.offline-content {
  background-color: #2a2a2a;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
}

.offline-section {
  text-align: left;
  margin-bottom: 70px;
  padding-left: 100px;
  padding-right: 100px;
}

.offline-section h3 {
  color: #fff;
  font-size: 25px;
  margin-bottom: 15px;
  margin-top: 90px;
  text-align: center;
}

.offline-list {
  list-style-type: none;
  padding-left: 0;
}

.offline-list li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.offline-list li::before {
  content: "•";
  color: #cc0000;
  position: absolute;
  left: 0;
}

.comparison-table {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 50px;
}
.comparison-table th {
  background-color: #1a1a1a;
  color: #fff;
}
.comparison-table tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.05);
}

@keyframes shake {
  0%, 87.5% {
    transform: translate(0, 0) rotate(0deg);
  }
  88% { transform: translate(1px, 1px) rotate(0deg); }
  89% { transform: translate(-1px, -2px) rotate(-1deg); }
  90% { transform: translate(-3px, 0px) rotate(1deg); }
  91% { transform: translate(3px, 2px) rotate(0deg); }
  92% { transform: translate(1px, -1px) rotate(1deg); }
  93% { transform: translate(-1px, 2px) rotate(-1deg); }
  94% { transform: translate(-3px, 1px) rotate(0deg); }
  95% { transform: translate(3px, 1px) rotate(-1deg); }
  96% { transform: translate(-1px, -1px) rotate(1deg); }
  97% { transform: translate(1px, 2px) rotate(0deg); }
  98% { transform: translate(1px, -2px) rotate(-1deg); }
  99%, 100% { transform: translate(0, 0) rotate(0deg); }
}

.shake-airplane {
  display: inline-block;
  animation: shake 2s ease-in-out infinite;
}

.offline-view .hero {
  text-align: center;
  padding: 40px 20px;
  background-color: #1a1a1a;
  border-radius: 10px;
  margin-bottom: 30px;
}

.offline-view .hero-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.offline-view .feature-highlights {
  background-color: #2a2a2a;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 100px;
}

.offline-view .feature-highlights h3 {
  color: #cc0000;
  text-align: center;
  margin-bottom: 30px;
  background: white;
  font-size: 40px;
}

.offline-view .feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.offline-view .feature-item {
  text-align: center;
  padding: 20px;
  background-color: #333;
  border-radius: 8px;
  transition: transform 0.3s ease;
  height: 90px;
  align-content: space-evenly;
}

.offline-view .feature-item:hover {
  transform: translateY(-5px);
}

.offline-view .feature-item i {
  font-size: 2.5em;
  color: #cc0000;
  margin-bottom: 15px;
}

.offline-view .feature-item h4 {
  color: #fff;
  margin-bottom: -60px;
  font-size: 14px;
}

.offline-view .feature-item p {
  color: #ccc;
  font-size: 0.9em;
}

@keyframes darken {
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}

.hero {
  position: relative;
  height: 500px;
  background-image: url('./hero1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  animation: darken 10s infinite;
  z-index: 1;
}


.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  z-index: 2;
}

.highlightoffline {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
}

.hero-sub-heading {
  font-size: 24px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}


.top-cta {
  margin-top: 20px;
}

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  place-content: flex-end;
}

.offline-access-section {
  background-color: #222;
  padding: 2rem;
  border-radius: 10px;
  margin-top: 2rem;
}

.offline-access-section h3 {
  color: #fff;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.comparison-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.comparison-item {
  display: flex;
  align-items: center;
  background-color: #333;
  padding: 1rem;
  border-radius: 5px;
}

.comparison-item i {
  font-size: 1.5rem;
  margin-right: 1rem;
}

.comparison-item.offline i {
  color: #ff4136;
}

.comparison-item.online i {
  color: #2ecc40;
}

.comparison-item span {
  color: #fff;
  font-size: 1.2rem;
}

.impactful-statement {
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  font-weight: bold;
  margin-top: 1.5rem;
}



.scaleicons {
  transform: scale(0.3);
  transform-origin: center;
  position: absolute;
  margin-top: -15px;
  filter: invert(1);
}

.scaleicons2 {
  filter: invert(1);
  float: right;
  margin-left: -20px;
  margin-right: -40px;
  margin-top: -66px;
  position: relative;
  transform: scale(.15);
  transform-origin: center;
}

.scaleicons3 {
  transform: scale(0.24);
  transform-origin: center;
  position: absolute;
  filter: invert(24%) sepia(91%) saturate(3253%) hue-rotate(357deg) brightness(107%) contrast(116%);
  margin-top: 0px;
  position: revert;
  float: right;
  display: inline;
}

.scaleicons3b {
  transform: scale(0.24);
  transform-origin: center;
  position: absolute;
  filter: invert(47%) sepia(99%) saturate(3253%) hue-rotate(85deg) brightness(107%) contrast(116%);
  margin-top: 0px;
  position: revert;
  float: right;
  display: inline;
}

.bg-compass {
  width: 145px; height: 150px;
  background: url('css_sprites.png') -10px -10px;
}


.bg-head_side_brain {
  width: 150px; height: 150px;
  background: url('css_sprites.png') -10px -180px;
}


.bg-life_ring {
  width: 150px; height: 150px;
  background: url('css_sprites.png') -10px -350px;
}


.bg-shield_halved_solid {
  width: 150px; height: 150px;
  background: url('css_sprites.png') -10px -520px;
}


.bg-users {
  width: 188px; height: 150px;
  background: url('css_sprites.png') -10px -690px;
}


.bg-wifi_slash {
  width: 188px; height: 150px;
  background: url('css_sprites.png') -10px -860px;
}


.bg-check_circle {
  width: 150px; height: 150px;
  background: url('css_sprites.png') -10px -1030px;
}


.bg-circle_xmark {
  width: 150px; height: 150px;
  background: url('css_sprites.png') -10px -1200px;
}


.bg-chevron_right {
  width: 84px; height: 150px;
  background: url('css_sprites.png') -10px -1370px;
}

.feature-item {
  text-align: center;
  padding: 20px;
  background-color: #333;
  border-radius: 8px;
  transition: transform 0.3s ease;
  height: 150px; /* Adjusted height to fit scaled image */
  align-content: space-evenly;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
}


@media (max-width: 970px) {
  .main-heading {
      font-size: 80px;
      line-height: 86px;
  }
  .sub-heading {
    font-size: 30px;
  }
  .offline-view, .online-view {
    margin: 0px;
  }
  .sub-head2 {
    font-size: 32px;
  }
  .offline-section {
    padding-left: 5px;
    padding-right: 5px;
  }
  .cta-button {
    width: 100%;
    margin-top: 15px;
  }
  .email-input {
    max-width: 300px;
  }
  .offline-view .feature-highlights h3 {
    font-size: 20px;
  }
  .cta-button {
    font-size: 18px;
    max-height: 50px;
  }
  .scaleicons2 {
    margin-top: -65px;
    display: none;
  }
  .offline-view h2{
    font-size: 30px;
    margin-top: 60px;
  }
  .highlightoffline{
    font-size: 24px;
  }
  .offline-access-section h3 {
    font-size: 1.2rem;
  }

  .comparison-grid {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .comparison-item {
    padding: 0.5rem;
    justify-content: center; /* Center the content horizontally */
  }

  .comparison-item span {
    font-size: 0.9rem;
    margin-left: -45px;
  }

  .scaleicons3, .scaleicons3b {
    transform: scale(0.15);
    margin-left: -50px;
  }

  .impactful-statement {
    font-size: 0.9rem;
  }

}

.bookshelf-section {
  position: relative;
}

.bookshelf {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.book {
  text-align: center;
  cursor: pointer;
}

.book img {
  width: 100%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  max-width: 153px;
}

.book img:hover {
  transform: scale(1.05);
}

.bookshelf-overlay {
  display: flex;
  height: 200px;
  justify-content: center;
  left: 0;
  padding-bottom: 20px;
  position: absolute;
  right: 0;
  margin-top: -235px;
  background: linear-gradient(180deg, rgba(42, 42, 42, 0) 0%, rgba(42, 42, 42, 0.7) 30%, rgba(42, 42, 42, 1) 100%);
  align-items: self-end;
}

.bookshelf-overlay p {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
}

@media (max-width: 970px) {
  .bookshelf {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .bookshelf {
    grid-template-columns: 1fr;
  }
}

.ctaenabled {
  background-color: green !important;
}

.unauthorized {
  text-align: center;
  color: red;
  font-size: 1.5em;
  margin-top: 50px;
}

.cache-status {
  align-items: center;
  bottom: 20px;
  color: #fff;
  display: flex;
  font-size: 16px;
  position: fixed;
  right: 20px;
}

.cache-dot {
  animation: flash 1s infinite;
  background-color: red;  /* Start as red by default */
  border-radius: 50%;
  height: 10px;
  margin-left: 8px;
  width: 10px;
}

@keyframes flash {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.cache-status {
  cursor: pointer;
}

.cache-status:hover {
  opacity: 0.8;
}

.pdf-viewer-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.pdf-viewer-modal iframe {
  width: 80%;
  height: 90%;
}

.pdf-viewer-modal button {
  background-color: #fff;
  border: none;
  padding: 10px 20px;
  margin-bottom: 20px;
  cursor: pointer;
  z-index: 10000;
}

.lnurl {
  font-size: 8px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}